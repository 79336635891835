export type CreateOptionTypes = {
    image: string;
    fallback: string;
    label: string;
    slug: string;
};

export const listOfCreateOptions: Array<CreateOptionTypes> = [
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/courses.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/courses.png`,
        slug: 'course',
        label: 'Courses',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/events.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/events.png`,
        slug: 'event',
        label: 'Events',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/e-learning.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/e-learning.png`,
        slug: 'e-learning',
        label: 'E-Learning',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/webinars.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/webinars.png`,
        slug: 'webinar',
        label: 'Webinars',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/memberships.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/memberships.png`,
        slug: 'membership',
        label: 'Memberships',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/office-learning.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/office-learning.png`,
        slug: 'office-learning',
        label: 'Office Learning',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/blended-courses.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/blended-courses.png`,
        slug: 'blended-program',
        label: 'Blended Courses',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/workshops.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/workshops.png`,
        slug: 'workshop',
        label: 'Workshops',
    },
    {
        image: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/training.webp`,
        fallback: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/CreateWithCademyCarousel/training.png`,
        slug: 'training',
        label: 'Training',
    },
];
