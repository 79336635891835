import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import Layout from '../layouts/Layout';
import { LogosStrip } from '../components/LogosStrip';
import { CreateWithCademyCarousel } from '../components/CreateWithCademyCarousel';
import { AdvertiseOnCademyBanner } from '../components/MarketingBanner/AdvertiseOnCademyBanner';
import Head from 'next/head';
import { Features } from '../components/Features';
import { TestimonialsCarousel } from '../components/TestimonialsCarousel';
import HeroBanner from '../components/HomePage/HeroBanner';
import { FeaturesCarousel } from '../app/components/FeaturesCarousel';
import classNames from 'classnames';

export const HomePage: FunctionComponent = () => {
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}`,
        potentialAction: {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/courses?q={searchTerm}`,
            },
            'query-input': 'required name=searchTerm',
        },
    };

    return (
        <Layout
            title="Cademy"
            description="Discover thousands of courses across the UK or create your own courses, workshops and events easier than ever!"
            themeColor="#FFFFFF"
        >
            <Head>
                <script
                    id="sitelinks-search-schema"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(schema),
                    }}
                />
            </Head>

            <main className={styles.main}>
                <HeroBanner />
                <h2
                    className={classNames('section_heading', styles.featuresCarouselHeading)}
                    id="features-carousel"
                >
                    Explore What You Can Do
                </h2>
                <FeaturesCarousel />

                <section className={styles.shaded_section}>
                    <div className="container">
                        <h2 className="section_heading" id="features-list">
                            All The <span className={styles.blueText}>Features</span> You Need
                        </h2>
                        <Features />
                    </div>
                </section>
                <section className={styles.section}>
                    <div className={styles.create_with_cademy_container}>
                        <div className="container">
                            <CreateWithCademyCarousel
                                heading="What Will You Create Today?"
                                cardLink={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/for-educators`}
                                subheading="Learn more about what you can create with Cademy"
                            />
                        </div>
                    </div>
                </section>
                <section className={styles.shaded_section}>
                    <TestimonialsCarousel />
                </section>
                <section>
                    <div className="container">
                        <LogosStrip heading="Trusted By" category="clients" />
                    </div>
                </section>
                <section>
                    <div className="container">
                        <AdvertiseOnCademyBanner />
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default HomePage;
