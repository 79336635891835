import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { ImageWithFallback } from '../../../ImageWithFallback';

export type HeroCardTypes = {
    image: string;
    fallback: string;
    label: string;
};

export const HeroCard: FunctionComponent<HeroCardTypes> = ({ image, fallback, label }) => {
    return (
        <div className={styles.card}>
            <ImageWithFallback
                src={image}
                fallback={fallback}
                height={200}
                width={200}
                alt={label}
                className={styles.image}
            />
            <h3 className={styles.label}>{label}</h3>
        </div>
    );
};
