import { FunctionComponent } from 'react';
import ButtonLink from '../../ButtonLink';
import { MarketingBanner } from '..';

export const AdvertiseOnCademyBanner: FunctionComponent = () => {
    return (
        <MarketingBanner
            heading="Promote on Cademy"
            subheading="Advertise your business and services on the Cademy Marketplace."
            imgSrc={`${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/MarketingBanner/promote-banner.webp`}
            imgFallback={`${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/MarketingBanner/promote-banner.png`}
            imgAlt="Promote on Cademy"
            color="blue"
        >
            <ButtonLink preset="default" aria-label="Find out more" href="/advertise">
                Find out more
            </ButtonLink>
        </MarketingBanner>
    );
};
