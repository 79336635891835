import { HeroCardTypes } from './HeroCard';

const baseUrl = `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/home/HeroBanner`;

export const cards: HeroCardTypes[] = [
    {
        image: `${baseUrl}/webinars-01.webp`,
        fallback: `${baseUrl}/webinars-01.png`,
        label: 'Webinars',
    },
    {
        image: `${baseUrl}/events-01.webp`,
        fallback: `${baseUrl}/events-01.png`,
        label: 'Events',
    },
    {
        image: `${baseUrl}/courses-01.webp`,
        fallback: `${baseUrl}/courses-01.png`,
        label: 'Courses',
    },
    {
        image: `${baseUrl}/memberships-02.webp`,
        fallback: `${baseUrl}/memberships-02.png`,
        label: 'Memberships',
    },
    {
        image: `${baseUrl}/coaching-03.webp`,
        fallback: `${baseUrl}/coaching-03.png`,
        label: 'Coaching',
    },
    {
        image: `${baseUrl}/team-training-01.webp`,
        fallback: `${baseUrl}/team-training-01.png`,
        label: 'Team Training',
    },
    {
        image: `${baseUrl}/tuition-01.webp`,
        fallback: `${baseUrl}/tuition-01.png`,
        label: 'Tuition',
    },
    {
        image: `${baseUrl}/workshops-01.webp`,
        fallback: `${baseUrl}/workshops-01.png`,
        label: 'Workshops',
    },
    {
        image: `${baseUrl}/programmes-01.webp`,
        fallback: `${baseUrl}/programmes-01.png`,
        label: 'Programmes',
    },
    {
        image: `${baseUrl}/certifications-01.webp`,
        fallback: `${baseUrl}/certifications-01.png`,
        label: 'Certifications',
    },
    {
        image: `${baseUrl}/classes-03.webp`,
        fallback: `${baseUrl}/classes-03.png`,
        label: 'Classes',
    },
    {
        image: `${baseUrl}/subscriptions-01.webp`,
        fallback: `${baseUrl}/subscriptions-01.png`,
        label: 'Subscriptions',
    },
    {
        image: `${baseUrl}/tutoring-01.webp`,
        fallback: `${baseUrl}/tutoring-01.png`,
        label: 'Tutoring',
    },
    {
        image: `${baseUrl}/online-courses-01.webp`,
        fallback: `${baseUrl}/online-courses-01.png`,
        label: 'Online Courses',
    },
    {
        image: `${baseUrl}/e-learning-01.webp`,
        fallback: `${baseUrl}/e-learning-01.png`,
        label: 'eLearning',
    },
];
