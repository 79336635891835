import { TestimonialTypes } from '../Testimonial';

const baseUrl = `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/Testimonials`;

export const testimonials: TestimonialTypes[] = [
    {
        image: `${baseUrl}/michelle-hickman-furzie.webp`,
        fallback: `${baseUrl}/michelle-hickman-furzie.png`,
        testimonial: `It is a wonderful booking platform - just what I always wanted and so much easier to keep track of everything. I think you beat Eventbrite and Ticket Tailor.`,
        author: 'Michelle Hickman - Furzie',
    },
    {
        image: `${baseUrl}/nicola-askham-thedatagovernance-coach.webp`,
        fallback: `${baseUrl}/nicola-askham-thedatagovernance-coach.png`,
        testimonial: 'I absolutely love using Cademy, it makes hosting my course so streamlined.',
        author: 'Nicola Askham - Data Gov. Coach',
    },
    {
        image: `${baseUrl}/jen-hunter-clinical-training.webp`,
        fallback: `${baseUrl}/jen-hunter-clinical-training.png`,
        testimonial: `The functionality of Cademy (and pricing!) greatly outperforms our previous setup on Eventbrite. The customer service team are truly brilliant. Highly recommended.`,
        author: 'Jen Hunter - Hunter Clinical Training',
    },
    {
        image: `${baseUrl}/giada-gaslini-invisible-caims.webp`,
        fallback: `${baseUrl}/giada-gaslini-invisible-caims.png`,
        testimonial: `I've rarely found such an incredible team of professionals to work with. Quick replies, solved in a bunch of minutes. I highly recommend the Cademy Team.`,
        author: 'Giada Gaslini - Invisible Caims',
    },
    {
        image: `${baseUrl}/yanina-stockings-green-spiral-willow.webp`,
        fallback: `${baseUrl}/yanina-stockings-green-spiral-willow.png`,
        testimonial: `Switching my bookings to Cademy was the best thing I could do...it's freed up so much of my time. I highly recommend it.`,
        author: 'Yanina Stockings - Green Spiral Willow',
    },
    {
        image: `${baseUrl}/richard-cartwright-heat-engineer.webp`,
        fallback: `${baseUrl}/richard-cartwright-heat-engineer.png`,
        testimonial: `Have to say, this platform is awesome! And the team have really pulled out all the stops to make your events really easy to manage. Great work!`,
        author: 'Richard Cartwright - Heat Engineer',
    },
    {
        image: `${baseUrl}/darren-robathan-comply-sense.webp`,
        fallback: `${baseUrl}/darren-robathan-comply-sense.png`,
        testimonial: `Great service, great product, only pay when you earn, what's not to love!        `,
        author: 'Darren Robathan - Comply Sense',
    },
    {
        image: `${baseUrl}/kimbo-betty.webp`,
        fallback: `${baseUrl}/kimbo-betty.png`,
        testimonial: `Cademy is brilliant for my courses. Simple, embedded straight into my website and gives me a simple CRM at half the price of Evetbrite. Brilliant!`,
        author: 'Kimbo Betty - Heating Academy',
    },
    {
        image: `${baseUrl}/louise-gibbon-endfield-farm-pottery.webp`,
        fallback: `${baseUrl}/louise-gibbon-endfield-farm-pottery.png`,
        testimonial: `I have felt so confident in asking for help knowing that I will receive a reply so quickly and even on the weekends. I Feel very supported .`,
        author: 'Louise Gibbon - Endfield Farm Pottery',
    },
    {
        image: `${baseUrl}/claire-paul-the-flo-coach.webp`,
        fallback: `${baseUrl}/claire-paul-the-flo-coach.png`,
        testimonial: `Thank you Cademy 🎓 I can't recommend your platform more highly and your support team are superstars 🤩`,
        author: 'Claire Paul - The Flo Coach',
    },
    {
        image: `${baseUrl}/sally-smith-physics-partners.webp`,
        fallback: `${baseUrl}/sally-smith-physics-partners.png`,
        testimonial: `Cademy is a brilliant system that we've been using for over a year, after switching from a very well know event booking system! I highly recommend it!`,
        author: 'Sally Smith - Physics Partners',
    },
    {
        image: `${baseUrl}/jane-galley-loopy-s-place.webp`,
        fallback: `${baseUrl}/jane-galley-loopy-s-place.png`,
        testimonial: `I was looking for a booking system that I could embed on my website that was easy to use. Cademy has provided me with all that. I'm really happy with it.`,
        author: "Jane Galley - Loopy's Place",
    },
];
