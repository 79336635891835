import { FunctionComponent, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { ImageWithFallback } from '../ImageWithFallback';

export type TestimonialTypes = PropsWithChildren & {
    image: string;
    fallback: string;
    testimonial: string;
    author: string;
};

export const Testimonial: FunctionComponent<TestimonialTypes> = ({
    image,
    fallback,
    testimonial,
    author,
}) => {
    return (
        <div className={styles.card}>
            <ImageWithFallback
                src={image}
                fallback={fallback}
                height={60}
                width={60}
                alt={`Headshot of ${author}`}
                className={styles.author_img}
            />
            <div className={styles.stars}>★★★★★</div>
            <div className={styles.author}>
                <p>{author}</p>
            </div>
            <div className={styles.testimonial}>
                <p>{testimonial}</p>
            </div>
        </div>
    );
};
