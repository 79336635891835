import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { ImageWithFallback } from '../ImageWithFallback';

type SupportedColors = 'blue' | 'black';

const COLORS: Record<SupportedColors, string> = {
    blue: `linear-gradient(to right, rgba(61, 86, 224, 1), rgba(61, 86, 224, 0.8), rgba(61, 86, 224, 0.199), rgba(61, 86, 224, 0.1))`,
    black: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.199), rgba(0, 0, 0, 0.1))`,
};

type MarketingBannerProps = {
    heading?: string;
    subheading?: string;
    children: React.ReactNode;
    imgSrc: string;
    imgFallback: string;
    imgAlt: string;
    color: SupportedColors;
};

export const MarketingBanner: FunctionComponent<MarketingBannerProps> = ({
    heading,
    subheading,
    children,
    imgSrc,
    imgFallback,
    imgAlt,
    color,
}) => {
    return (
        <section className={`rounded_image ${styles.banner_container}`}>
            <div className={styles.banner_image}>
                <div className={styles.background_gradient} style={{ background: COLORS[color] }}>
                    <div className={styles.cta_section}>
                        {heading ? <h2>{heading}</h2> : null}
                        {subheading ? <p>{subheading}</p> : null}
                        {children}
                    </div>
                </div>
                <ImageWithFallback
                    fallback={imgFallback}
                    alt={imgAlt}
                    src={imgSrc}
                    fill
                    sizes="75vw"
                    quality={100}
                />
            </div>
        </section>
    );
};
