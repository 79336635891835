import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { testimonials } from './testimonials';
import { Testimonial } from '../Testimonial';

export const TestimonialsCarousel: FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.carousel}>
                {testimonials.map((testimonial, i) => (
                    <Testimonial key={i} {...testimonial} />
                ))}
            </div>
        </div>
    );
};
